import {Routes} from '@angular/router';
import {AuthGuard} from "./core";

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'employee',
        pathMatch: 'full'
    },
    {
        path: 'category',
        loadComponent: () => import('./components/index').then(com => com.CategoryGridComponent),
      canActivate: [AuthGuard]
    },
    {
      path: 'employee',
      loadComponent: () => import('./components/index').then(com => com.EmployeeGridComponent),
      canActivate: [AuthGuard]
    },
    {
      path: 'department',
      loadComponent: () => import('./components/index').then(com => com.DepartmentGridComponent),
      canActivate: [AuthGuard]
    },
];
